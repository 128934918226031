<template>
<div class="uk-height-viewport">
	<div class="header">
		<div class="uk-container uk-flex uk-flex-left">
			<div class="menu-wrap">
				<div class="menu-toggle" v-show="!openNav" v-on:click="toggleMenu()">
					<svg viewBox="0 0 40 40" width="40" height="40">
						<rect y="0" width="40" height="8" rx="3" style="fill:rgb(255,255,255)"></rect>
						<rect y="16" width="40" height="8" rx="3" style="fill:rgb(255,255,255)"></rect>
						<rect y="32" width="40" height="8" rx="3" style="fill:rgb(255,255,255)"></rect>
					</svg>
				</div>
				<div class="menu-toggle" v-show="openNav" v-on:click="toggleMenu()">
					<svg viewBox="0 0 40 40" width="40" height="40">
						<line x1="0" y1="40" x2="40" y2="0" stroke="white" stroke-width="6"></line>
						<line x1="0" y1="0" x2="40" y2="40" stroke="white" stroke-width="6"></line>
					</svg>
				</div>
				<div class="menu-nav" v-show="openNav">
					<router-link to="/">Home</router-link>
					<router-link v-if="!finished" to="/participate">Πάρε Μέρος</router-link>
					<router-link to="/gifts">Δώρα</router-link>
					<router-link to="/winners">Νικητές</router-link>
					<hr/>
					<router-link to="/termsofuse">Όροι χρήσης του Microsite</router-link>
					<router-link to="/terms">Όροι Διαγωνισμού</router-link>
					<router-link to="/privacy">Πολιτική Απορρήτου</router-link>
					<router-link to="/cookies">Πολιτική Cookies</router-link>
					<router-link to="/drinkresponsible">Υπεύθυνη Κατανάλωση</router-link>
				</div>
			</div>
		</div>
	</div>
	<div class="main">
		<router-view/>
	</div>
	<div id="tm-footer" class="uk-position-bottom uk-position-fixed">
		<div class="uk-container uk-container-large">
			<div class="uk-child-width-1-3@l uk-child-width-1-1 tm-text-small" uk-grid="">
				<div class=" uk-text-bold uk-text-left uk-flex uk-flex-middle uk-visible@l">
					<div class="uk-panel">&nbsp;</div>
				</div>
				<div>
					<ul class="uk-subnav uk-flex-center uk-margin-remove uk-subnav-divider">
						<li><router-link to="/terms">Οροι Διαγωνισμου</router-link></li>
						<li><router-link to="/contact">ΦΟΡΜΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</router-link></li>
					</ul>
				</div>
				<div class="uk-text-bold uk-flex uk-flex-middle uk-flex-right uk-text-right uk-visible@l">
					<div class="uk-panel">Απολαύστε υπεύθυνα</div>
				</div>

			</div>
			<div class="uk-hidden@l tm-text-small uk-child-width-1-2 uk-flex-center uk-flex uk-margin-remove-top uk-padding-remove-top" uk-grid>
				<div>&nbsp;</div>
				<div class="uk-text-right">Απολαύστε υπεύθυνα</div>
			</div>

		</div>
	</div>
</div>

<!--<CookieConsent :storageType="'localstorage'" />-->
</template>

<script>
import store from '@/store/';
/*import CookieConsent from './components/CookieConsent';*/

import { useRoute, useRouter } from 'vue-router'
import { ref, watch, computed } from 'vue';
import UIkit from 'uikit';

export default {
	components: {
		/*CookieConsent*/
	},
	setup() {
		const openNav = ref('');
		const finished = computed(() => store.getters.getFinished);
		const Adult = computed(() => store.getters.getAdult);

		const closeOffcanvas = () => {
			UIkit.offcanvas("#offcanvas").hide();
		}
		const toggleMenu = () => {
			if (openNav.value == '') {
				openNav.value = 'open';
			} else {
				openNav.value = '';
			}
		}

		const route = useRoute()
		const router = useRouter()

		const storeInit = () => {
			store.dispatch('init')
			.then(() =>{
				setTimeout(() => {
					if (Adult.value) {
						if (route.fullPath === '/') {
							if (finished.value) {
								router.push({ path: 'finish' });
							}
						}
					}else{
						if (route.fullPath === '/terms') {
							router.push({ path: 'terms' });
						}else{
							router.push({ path: 'adult' });
						}
					}
				},200)
			});
		}

		storeInit();

		watch(route,
			(path) => {
				openNav.value = '';
				setTimeout(() => {
					if (Adult.value) {
						if (path.fullPath === '/') {
							if (finished.value) {
								router.push({ path: 'finish' });
							}
						} else if (path.fullPath === '/participate') {
							if (finished.value) {
								router.push({ path: 'finish' });
							}
						} else if (path.fullPath === '/finish') {
							if (!finished.value) {
								router.push({ path: '/' });
							}
						} else if (path.fullPath === '/adult') {
							if (!finished.value) {
								router.push({ path: '/' });
							} else if (finished.value) {
								router.push({ path: 'finish' });
							}
						}
					}else{
						if (path.fullPath === '/terms') {
							router.push({ path: 'terms' });
						}else{
							router.push({ path: 'adult' });
						}
					}
				},200);
			}, {flush: 'pre', immediate: true, deep: true}
		)


		watch(finished,(newValue, oldValue)=>{
			console.log(`Updating from  ${oldValue} to ${newValue}`);
			if (Adult.value) {
				if (route.fullPath === '/') {
					if (newValue) {
						router.push({ path: 'finish' });
					}
				} else if (route.fullPath === '/participate') {
					if (newValue) {
						router.push({ path: 'finish' });
					}
				} else if (route.fullPath === '/finish') {
					if (!newValue) {
						router.push({ path: '/' });
					}
				}
			}else{
				setTimeout(() => {
				if (route.fullPath === '/terms') {
					router.push({ path: 'terms' });
				}else{
					router.push({ path: 'adult' });
				}
			},200);
			}
		})

		watch(Adult,(newValue, oldValue)=>{
			console.log(`Adult Updating from  ${oldValue} to ${newValue}`);
			if (Adult.value) {
				if (route.fullPath === '/') {
					if (newValue) {
						router.push({ path: 'finish' });
					}
				} else if (route.fullPath === '/participate') {
					if (newValue) {
						router.push({ path: 'finish' });
					}
				} else if (route.fullPath === '/finish') {
					if (!newValue) {
						router.push({ path: '/' });
					}
				} else if (route.fullPath === '/adult') {
					if (newValue) {
						router.push({ path: '/' });
					}
				}
			}else{
				if (route.fullPath === '/terms') {
					router.push({ path: 'terms' });
				}else{
					router.push({ path: 'adult' });
				}
			}
		})

		return {
			Adult,finished, openNav, closeOffcanvas, toggleMenu
		}
	},
}
</script>

<style lang="scss">
@import "assets/css/fonts.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

$global-font-family: 'Gotham-Greek','Open Sans', sans-serif;
$global-color: #fff;
$global-link-color: #fff;
$base-heading-color: #fff;
$base-body-color: #fff;
$base-font-family: 'Gotham-Greek';
$base-heading-font-family: 'Gotham-Greek';
$form-label-font-size: 25px;
$form-height: 70px;
$base-body-font-weight: 400;
$base-heading-font-weight: 700;
$container-xsmall-max-width: 710px;
$button-primary-background: #fff;
$button-primary-color: #c49f4b;
$text-primary-color: #fff;
$button-padding-horizontal: 60px;
$button-font-size: 42px;
$button-line-height: 60px;
$base-h1-font-size-m: 68px;
$base-h1-font-size: 48px;
$base-h2-font-size-m: 48px;
$base-h3-font-size: 38px;
$form-radio-checked-icon-color: #ed1c24;
$form-radio-checked-background: #fff;
$form-color: #000;
$form-focus-color: #000;

// 2. Import default variables and available mixins.
@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
@mixin hook-card() { color: #fff; }
@mixin hook-form() { font-size: 25px; }
@mixin hook-form-label() { line-height: 40px; font-size: 20px; margin-bottom: 5px; display: block; }
@mixin hook-button() { padding: 0 40px; text-transform: none; border-radius: 40px; }

// 4. Import UIkit.
@import "uikit/src/scss/uikit-theme.scss";

.header { top: 0; left: 0; right: 0; position: fixed; z-index: 3; width: 100%; height: 80px; /*background-color: #fbb04c;*/ }
.menu-wrap { width: 200px; text-align: center; }
.menu-toggle { padding: 20px 0px 20px 0px; width: 110px;/*background-color: #112143;*/}
.menu-toggle rect{fill:#3f1f20 !important}
.menu-toggle line{stroke:#3f1f20 !important;background-color: #00144f;}
.menu-nav { display: none; outline: none; line-height: 30px; width: max-content; background-color: #008194; display: flex; flex-direction: column; justify-items: center; padding: 20px; }
.menu-nav a{font-weight: bold;color: #fff;}
.menu-nav a:hover { color: #c49f4b; text-decoration: underline; }


/*html.participate, html.contact, html.winners, html.terms{background-color:#fbb04c;}
html { min-height: 100%;background: rgb(56,145,59);background: -moz-linear-gradient(146deg, rgba(56,145,59,1) 0%, rgba(108,190,72,1) 60%, rgba(145,201,97,1) 100%);background: -webkit-linear-gradient(146deg, rgba(56,145,59,1) 0%, rgba(108,190,72,1) 60%, rgba(145,201,97,1) 100%);background: linear-gradient(146deg, rgba(56,145,59,1) 0%, rgba(108,190,72,1) 60%, rgba(145,201,97,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#38913b",endColorstr="#91c961",GradientType=1);	 padding-bottom: 60px; padding-top: 0px; }
html.participate, html.gifts{background: url(assets/bg.jpg) top center #fddd2a no-repeat;  background-size:auto;background-origin:content-box;}
html.home #tm-footer,html.gifts #tm-footer{background-color: #60ba46;}
html.home #tm-footer .uk-subnav > * > :first-child,html.gifts #tm-footer .uk-subnav > * > :first-child{color: #fff;}
html.home #tm-footer .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before,html.gifts #tm-footer .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before{border-left-color: #fff;}
*/
div#app{background-color: #008194;}
.giftsWrapper, .adultWrapper {background-color: #008194;}
.tm-wave{background: url(assets/wave.png) top center no-repeat;margin-top: -173px;height: 384px;
	&-big{margin-top: -384px;}
}


.tm-kv{background: url('assets/kv_big.jpg') top center no-repeat;height: 1567px;}

body.no-footer-bg #bg::after { display: none; }
#nav { width: 100%; z-index: 3; color: #ffffff; max-height: 92px; position: fixed; top: 0; font-size: 20px; text-align: center; transition: max-height 0.45s ease-out, background-color 0.45s ease-out; }
.tm-offcanvas-btn { z-index: 3; position: fixed; top: 0; left: 0; padding: 0 10px; min-height: 60px; }
#nav.open { max-height: 600px; background-color: #ed1c24; }
#nav a { outline: none; display: block; color: #000; padding: 5px 20px; font-size: 15px; font-weight: 800; }
#tm-footer { background-color: #eee7d5; font-size: 16px; line-height: 16px; padding: 14px 0 !important; text-transform: uppercase; font-weight: 700; z-index: 2;color: #3f2021; }
#tm-footer .uk-subnav > * > :first-child { font-size: 0.675rem; color: #3f2021; padding: 10px 0; font-weight: 700; }
#tm-footer .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before{border-left-color: #3f2021;}
.toggler > div { padding: 20px; display: inline-block; width: 200px; }
.toggler > div.closed { background-color: #ed1c24; }
.grecaptcha-badge { z-index: 2; }
.tm-button-tranformed { transform: skew(-20deg, -1deg); }
.tm-contest-dates { font-size: 17px; line-height: 24px;font-weight: 500}
.tm-contest-note{ font-size: 12px; line-height: 14px; font-weight: 500;}
.uk-text-danger { color: #ed1c24 !important; }
.uk-radio, .uk-checkbox { height: 26px; width: 26px; background-color: #ffffff;border-radius: 999px; }
.uk-radio:focus, .uk-checkbox:focus { border-color: #222; background-color: #ffffff; }
.uk-radio:checked:focus, .uk-checkbox:checked:focus, .uk-checkbox:indeterminate:focus { background-color:  black;  }
.uk-radio:checked, .uk-checkbox:checked, .uk-checkbox:indeterminate {background-color:  black;}
.uk-radio:checked { background: #231f20; }
.uk-radio:checked:after{ content: ''; width: 10px; height: 10px; border-radius: 50%; display: block; margin-top: 5px; margin-left: 5px; background-color:#fff; }
.uk-checkbox:checked:before{content: '\2713'; display: block;font-size:18px;  margin-left: 5px; color:#fff }
#teconsent { z-index: 3; position: fixed; bottom: 50px; left: 0; }
.tm-background-menu { background-color: #0176d3; height: 80px; }
.uk-text-bold { font-weight: 800; }
label a:hover,label a{color:#000;text-decoration: underline;font-weight: bold;}

.tm-color-black{color: black !important;}
.tm-color-blue{color: #00144f !important;}
.tm-color-white{color: #fff !important;}
.tm-color-yellow{color:#ffe507 !important;}
.tm-color-red{color:#ed1c24 !important;}

.tm-color-blueshadow{color:#00144f !important;text-shadow: 1px 1px 1px #ffe507;}
.trustarc-banner {position: fixed;bottom: 0;width: 100%;z-index: 9;}
.tm-button-secondary{background-color: #eee7d5 ;color:#008194}
.tm-button-secondary:hover{background-color: #005b6d ;color:#eee7d5}
.tm-button-secondary:disabled {opacity: 30%;}

.tm-text-xlarge {font-size: 2.5rem;line-height: 2.5;}
.tm-text-large {font-size: 2.5rem;line-height: 1.4;}
.tm-text-small {font-size: 0.675rem;line-height: 1.4;}

.uk-grid-divider > :not(.uk-first-column)::before {top: 30%;border-left: 5px solid #000;height: 70%;}

@media (max-width: 1200px) {
	.grecaptcha-badge { bottom: 60px !important; }
	/*#tm-footer .uk-subnav > * > :first-child { font-size: 13px; padding: 10px 14px; }*/
	/*.tm-ellipsis{min-height: ;}*/
}

.tm-age-input{font-size: 8vh !important; width: 10vh;height:14vh;background: transparent;color:#fff;text-align: center;border: 0.5vh  solid white;border-radius: 0; -webkit-box-shadow: none;box-shadow: none;font-weight: bold;  outline: none;-webkit-box-sizing: border-box;box-sizing: border-box; caret-color: transparent; padding: 0; line-height: 0; background-clip: padding-box; vertical-align: baseline;box-sizing: border-box;margin:1vh;caret-color: auto;margin-bottom: auto;}
.logoWrapper{background-color: #eee7d5;}
.cookie-toggler { z-index: 3; position: fixed; bottom: 70px; left: 20px; cursor: pointer; }

</style>
